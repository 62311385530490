.Home {
  text-align: center;
}

.Home-logo {
  width: 20%;
  margin-bottom: 16px;
}

.Home-header {
  background-color: #102739;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: #0ec0b4;
}

.Home-text {
  width: 40%;
}

.Home-link {
  color: #0ec0b4;
  border: 2px solid #0ec0b4;
  padding: 16px;
  display: block;
  border-radius: 4px;
  text-decoration: none;
}

.Home-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 600px) {
  .Home-logo {
    width: 40%;
  }

  .Home-text {
    width: calc(100% - 32px);
  }
}
