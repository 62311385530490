.IA {
  background-color: #102739;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.IA-logo {
  width: 200px;
  margin-bottom: 16px;
}

.IA-text {
  width: 40%;
  font-size: calc(8px + 2vmin);
  color: #0ec0b4;
}

.IA-link {
  color: #0ec0b4;
  border: 2px solid #0ec0b4;
  padding: 16px;
  display: block;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.IA-input-container {
  display: flex;
  width: 40%;
  justify-content: space-between;
  margin-bottom: 32px;
}

.IA-input {
  color: #0ec0b4;
  border: 2px solid #0ec0b4;
  padding: 16px;
  display: block;
  border-radius: 4px;
  text-decoration: none;
  background-color: #122d42;
  width: calc(50% - 8px);
  outline: none;
}

.IA-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.IA-spinner-ontainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 600px) {
  .IA-logo {
    width: 100px;
  }

  .IA-input-container {
    width: calc(100% - 32px);
  }

  .IA-text {
    width: calc(100% - 32px);
  }
}
